import React, {useContext} from "react";
import { GlobalDataContext } from "../../../../context/context";

const PromotionalVideo = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return(
        <div className="w-90 vh-90">
            <div className="w-100 vh-100 d-flex justify-content-center position-relative p-3"
            >
            
            <img 
                src={rpdata?.videosPromo?.[0].img}
                alt="promotional_photo"
                className="position-relative w-100 rounded-bottom-3"
            />
            <h2 className="position-absolute p-3 text-white">{rpdata?.dbSlogan?.[2]?.slogan}</h2>
            <p className="position-absolute bottom-50 z-3 text-white text-center p-3">{rpdata?.dbAbout?.[1]?.text}</p>
            <iframe
                            title="Promotional video"
                            className='w-50 h-50 rounded position-absolute bottom-0'
                            id="ytplayer"
                            type="text/html"
                            src={`https://www.youtube.com/embed/${rpdata?.videosPromo?.[0].link}?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=${rpdata?.videosPromo?.[0].link}`}
                            frameborder="0" allowfullscreen
            />
            </div>

        </div>
    )
}

export default PromotionalVideo